import UpdateBasket from '@sportson/core-web/libs/GrebbCommerceAPI/Basket/UpdateBasket';
import { setVirtualBasket } from '@sportson/core-web/state/ecommerce/norce/checkout';
import rollbackGiftCard from '@sportson/core-web/state/models/Basket/dispatches/rollbackGiftCard';

const determineVirtual = (items) => {
    let isVirtual = true;

    for (let index = 0; index < items.length; index++) {
        const item = items[index];
        let match = false;

        // if any item is missing flag_id_seed assume basket is not virtual
        if (!item.hasOwnProperty('flag_id_seed') && !item.hasOwnProperty('flagIdSeed')) {
            // Incase this backfires in the future, we should log a warning
            console.warn('An item missing flag_id_seed or flagIdSeed. Assuming basket is not virtual');
            isVirtual = false;
            break;
        }

        const itemFlagIdSeed = item.flag_id_seed || item.flagIdSeed;

        const ids = itemFlagIdSeed.split(',');
        for (let i = 0; i < ids.length; i++) {
            if (ids[i] === '3874') {
                match = true;
                break;
            }
        }

        if (!match) {
            isVirtual = false;
            break;
        }
    }

    return isVirtual;
};

export const handleVirtualBasket = async (
    basketId,
    basketItems,
    previousBasketItems,
    userSelectedStore,
    dispatch,
    payments,
) => {
    const isVirtual = determineVirtual(basketItems);
    const wasVirtual = determineVirtual(previousBasketItems);

    if (isVirtual !== wasVirtual) {
        if (isVirtual) {
            // Set store to sportson HQ
            await UpdateBasket(basketId, { selected_store_id: 762 });

            // If any gift cards are present, remove them to prevent a customer
            // from using a gift card to buy a gift card. If you buy a gift card with a gift card,
            // we assume ill intent and remove all applied gift cards.
            const retain24 = parseInt(process.env.REACT_APP_STORM_PAYMENT_METHOD_RETAIN24, 10);

            const giftCards = Array.isArray(payments)
                ? payments.filter((payment) => payment.paymentMethodId === retain24)
                : [];

            giftCards.forEach((giftCard) => {
                rollbackGiftCard(giftCard.paymentCode);
            });

            await rollbackGiftCard();
        } else if (userSelectedStore !== null) {
            await UpdateBasket(basketId, { selected_store_id: userSelectedStore.storeId });
        }

        await dispatch(setVirtualBasket(isVirtual));
    }
};
